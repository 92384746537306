<template src="./productRecommendation.html"></template>

<script>
import categoryCard from '../categoryCard/categoryCard';
import header from '../header/header';
import emptyRecommendation from '../emptyRecommendation/emptyRecommendation';

export default {
  name: 'productRecommendation',
  components: {
    categoryCard,
    recommendationHeader: header,
    emptyRecommendation,
  },
  data() {
    return {
      isRecomendedMaskLoading: false,
      recomendedMask: '',
    };
  },
  created() {
    this.$store.dispatch('me').then((response) => {
      if (response) {
        this.getRecomendedMask();
        this.$store.commit('setCategory', '');
        this.$store.commit('setSubCategory', '');
        this.$store.commit('setSubSubCategory', '');
      } else {
        this.$router.push('/recommendation/splash');
      }
    });
  },
  methods: {
    getRecomendedMask() {
      this.isRecomendedMaskLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/product-category/sociolla`, {
          params: {
            limit: 8,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.recomendedMask = response.data.data;
          } else {
            this.recomendedMask = [];
          }
          this.isRecomendedMaskLoading = false;
        })
        .catch((error) => {
          this.recomendedMask = [];
          this.isRecomendedMaskLoading = false;
        });
    },
    setCategory(item) {
      this.$store.commit('setCategory', item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'productRecommendation';
.home-page {
  .main-section {
    background: #f8f8f8;
    padding: 50px 0px 50px;
    .welcome-text {
      padding: 0px dim(178, vw) 38px;
    }
    .back-btn {
      margin: 0px 25px 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      a {
        background: #ffe4ed;
        padding: 12px 20px;
        font-family: 'lato-medium';
        font-size: 20px;
        color: #b32656;
        line-height: 27px;
        letter-spacing: 1.67px;
        border-radius: 30px;
        text-transform: uppercase;
      }
    }
    .promotion-product {
      padding: 0px 43px 0px;
      clear: both;
      ul {
        margin: 0px;
        padding: 0px;
        .category-card {
          box-shadow: 6px 8px 9px 0 rgba(0, 0, 0, 0.1);
          border-radius: 0px 0px 6px 6px;
          .col-md-6 {
            li.block-product {
              margin: 0 0 26px 0;
              width: 100%;
              .info {
                span.title {
                  min-height: 120px;
                }
              }
            }
          }
        }
      }
      .beauty-text {
        font-family: 'lato-medium';
        font-size: 24px;
        color: #000000;
        text-align: center;
        opacity: 0.6;
        margin-top: 20px;
        span {
          font-family: 'lato-bold';
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .home-page .main-section .promotion-product {
    padding: 0px 6.7037vw 30px;
  }
  .home-page .main-section .promotion-product ul .col-md-6 {
    width: 50%;
  }
}
</style>
